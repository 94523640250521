@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/variables.scss';

$nav_min_width: 200px;
$nav_max_width: 250px;

.container {
  height: 100vh;
}

.bottom {
  width: 100%;
  flex-grow: 1;
  background: white;
  display: flex;
}

.form {
  background: #fff;
  margin: 0 auto;
  padding: 0 40px;
  max-width: 500px;
  border: solid 1px #ebecec;
  box-shadow: 0 0 0;
  margin-top: 60px;
  margin-bottom: 60px;
}

.title {
  font-size: 40px;
  font-weight: 300;
  text-align: center;
  margin-top: 30px;
}

.subTitle {
  font-size: 20px;
  margin-bottom: 30px;
  text-align: center;
}

a {
  color: var(--primary);
  text-decoration: none;
}

.checkbox {
  font-size: 12px;
}

.topBar {
  display: flex;
  background: white;
  border-bottom: 1px solid rgb(221, 221, 221);
}

.topBarLeft {
  display: flex;
  padding: 20px 25px;
  align-items: center;
  border-right: 1px solid #ccc;
  box-sizing: border-box;
}

.password-message-error {
  color: var(--error-red);
  font-size: 12px;
}

.iam-register__terms__container {
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.iam-register__register-button__container {
  margin-bottom: 16px;
}

.iam-register__terms__cr__container {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.25px;
}
